import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { PhoneInput, Combobox } from 'hds-react';
import PlaygroundPreview from '../../../components/Playground';
import TabsLayout from './tabs.mdx';
import InternalLink from '../../../components/InternalLink';
export const CountryCodeExample = () => {
  const options = [{
    label: 'Finland (+358)'
  }, {
    label: 'UK (+46)'
  }];
  return <>
      <p id="work-phone" style={{
      fontSize: '18px',
      fontWeight: 'bold'
    }}>
        Work phone
      </p>
      <div style={{
      display: 'grid',
      gridTemplateColumns: '200px 300px',
      columnGap: '16px'
    }}>
        <Combobox id="combobox1" label="Country code" helper="Assistive text" aria-describedby="work-phone" options={options} defaultValue={{
        label: 'Finland (+358)'
      }} required toggleButtonAriaLabel="Choose country code" mdxType="Combobox" />
        <PhoneInput id="input2" defaultValue="0451234567" aria-describedby="work-phone" label="Phone number" required={true} mdxType="PhoneInput" />
      </div>
    </>;
};
export const _frontmatter = {
  "slug": "/components/phone-input",
  "title": "PhoneInput",
  "navTitle": "PhoneInput"
};
const layoutProps = {
  CountryCodeExample,
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}>{`Usage`}<a parentName="h2" {...{
        "href": "#usage",
        "aria-label": "usage permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "example",
      "style": {
        "position": "relative"
      }
    }}>{`Example`}<a parentName="h3" {...{
        "href": "#example",
        "aria-label": "example permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <PlaygroundPreview mdxType="PlaygroundPreview">
  <PhoneInput defaultValue="0451234567" helperText="Assistive text" label="Phone number" style={{
        maxWidth: '320px'
      }} mdxType="PhoneInput" />
    </PlaygroundPreview>
    <h3 {...{
      "id": "principles",
      "style": {
        "position": "relative"
      }
    }}>{`Principles`}<a parentName="h3" {...{
        "href": "#principles",
        "aria-label": "principles permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`A label should always be provided with a phone number input.`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`Make sure that the label is clear and concise. The user should immediately understand what phone number they are supposed to input.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`If a specific phone number format is required, describe the format in the assistive text. `}
        <ul parentName="li">
          <li parentName="ul">{`Generally it is recommended to allow all common formats and fix the format automatically for the user if needed.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Unless you can fill the input for the user automatically, a default value should not be set.`}
        <ul parentName="li">
          <li parentName="ul">{`A placeholder text is often not used either. If a placeholder is used, it is a good practice to make them easily distinguishable from the actual input. A recommended way to do this is to format the placeholder text like this: "`}<em parentName="li">{`E.g. 0401234567`}</em>{`".`}</li>
        </ul>
      </li>
      <li parentName="ul">{`If a country code must be inputted, a separate `}<InternalLink href="/components/dropdown#combobox-single-selection" mdxType="InternalLink">{`HDS Combobox input`}</InternalLink>{` should be used alongside the phone number input.`}</li>
    </ul>
    <h3 {...{
      "id": "variations",
      "style": {
        "position": "relative"
      }
    }}>{`Variations`}<a parentName="h3" {...{
        "href": "#variations",
        "aria-label": "variations permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <h4 {...{
      "id": "default",
      "style": {
        "position": "relative"
      }
    }}>{`Default`}<a parentName="h4" {...{
        "href": "#default",
        "aria-label": "default permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`A default variant of the phone number input.`}</p>
    <p>{`Note! A default value is used here for demonstration purposes. It should not be used unless the input can be automatically filled for the user.`}</p>
    <PlaygroundPreview mdxType="PlaygroundPreview">
  <PhoneInput defaultValue="0451234567" helperText="Assistive text" label="Phone number" style={{
        maxWidth: '320px'
      }} mdxType="PhoneInput" />
    </PlaygroundPreview>
    <h4 {...{
      "id": "with-a-country-code-selection",
      "style": {
        "position": "relative"
      }
    }}>{`With a country code selection`}<a parentName="h4" {...{
        "href": "#with-a-country-code-selection",
        "aria-label": "with a country code selection permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`If a country code is a required input (or otherwise critical), it should be asked in a separate input using `}<InternalLink href="/components/dropdown#combobox-single-selection" mdxType="InternalLink">{`HDS Combobox.`}</InternalLink></p>
    <p>{`These two input are linked together using a fieldset title and `}<inlineCode parentName="p">{`aria-desribedby`}</inlineCode>{` attribute. This way is is clear for screen readers which phone number these two inputs relate to.`}</p>

    <PlaygroundPreview mdxType="PlaygroundPreview">
  <CountryCodeExample mdxType="CountryCodeExample" />
    </PlaygroundPreview>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      